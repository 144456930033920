<!-- box1 -->


// #d0c5a7   #397290  #e78046 #7abbb7
<template>
  <div id="app">
      <div id="belowStandardAnalyse" style="width: 100%; height: 100%"></div>
  </div>
</template>
<script>
export default {
  name:"app",
  components: {},
  data() {
    //这里存放数据
    return {
        option : {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value'
            },
            yAxis: {
                type: 'category',
                data: ['计算机学院', '机械学院', '电子工程学院', '经济学院', '管理学院', 
                       '2021计算机学院', '2021机械工程学院','2021电子工程学院','2021管理学院','2021经济学院',
                       '2020计算机学院', '2020机械工程学院','2020电子工程学院','2020经济学院','2020管理学院',
                       '2019计算机学院', '2019电子工程学院','2019机械工程学院','2019经济学院','2019管理学院',
                       '2018计算机学院', '2018机械工程学院','2018电子工程学院','2018经济学院','2018管理学院',]
            },
            series: [
                {
                // name: 'Direct',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: [320, 302, 301, 334, 390, 330, 320]
                },
                {
                name: '总人数',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                itemStyle:{
                        normal:{
                          color:'#e78046'
                          }
                        },
                data: [1890, 1849, 1600, 1256, 1648, 483, 481,407,407,316,498,465,429,307,399,467,396,472,315,423,442,431,368,318,419]
                },
                {
                name: '未获学分人数',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                itemStyle:{
                        normal:{
                          color:'#397290'
                          }
                        },
                data: [527,243,396,223,262,370,43,300,209,182,105,140,56,25,25,40,37,56,15,21,12,4,3,1,7]
                },
                {
                name: '获得1学分人数',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                itemStyle:{
                        normal:{
                             color:'#d0c5a7'
                          }
                        },
                data: [444,367,345,232,298,74,64,84,137,94,227,188,171,98,102,125,90,112,39,52,18,3,0,1,7]
                },
                {
                name: '获得2学分人数',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                itemStyle:{
                        normal:{
                          color:'#7abbb7'
                          }
                        },
                data: [898,875,859,782,1051,18,10,23,24,21,166,137,202,184,272,302,269,304,261,350,412,424,365,316,405]
                }
            ]
            }
        };



      
  },
  props: ["data"],
  computed: {},
  watch: {
    data() {
      this.customized();
    },
  },
  methods: {
    customized() {

      this.option.series[0].data = this.data;
      let myChart = this.$echarts.init(
        document.getElementById("belowStandardAnalyse")
      );
      myChart.setOption(this.option);
      window.onresize = function(){
        myChart.resize();
      }
    },
  },
  mounted() {
    this.customized();
  },
};
</script>
<style lang='less' scoped>
#app {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 10px;
}
.detail {
  width: 120%;
  text-align:center;;
  background-color: #fff;
}
</style>
