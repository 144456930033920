<!-- box1 -->
<template>
  <div class="box">
    <div class="detail">
      <div id="standardRecord" style="width: 100%; height: 100%"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      option :{
        legend: {
            bottom:0
        },
        grid:{
                left:'11%',
                right:'2%',
                buttom:'1%',
                containLable:true
          },
       
        series: [
            {
                name: '面积模式',
                type: 'pie',
                radius: [5, 50],
                center: ['50%', '38%'],
                roseType: 'angle',
                itemStyle: {
                    borderRadius: 2,
                    color:['#d0c5a7','#397290','#e78046','#7abbb7']
                 
                }, 
                label: {
                    normal: {
                        formatter: '{b} : {c}',
                        
                        // + '\n\r' + '({d}%)',
                        show: true,
                        // position: 'left'
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    }
                },
                labelLine:{
                  normal:{
                    show:true,
                  }
                }
                },
            
        ]
     }
};

         
  },
  props: ["data"],
  computed: {},
  watch: {
    data() {
      this.customized();
    },
  },
  methods: {
    customized() {
      this.option.series[0].data = this.data;
      let myChart = this.$echarts.init(
        document.getElementById("standardRecord")
      );
      myChart.setOption(this.option);
    },
  },
  mounted() {
     window.onresize = function() {
            _this.myChart.resize()
    }

    this.customized();
  }
};
</script>
<style lang='less' scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
}
.detail {
  width: 80%;
  text-align: left;
  background-color: #fff;
}

</style>