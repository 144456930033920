import axios from 'axios'
import store from '../store';
import router from "../router";
import {
    // MessageBox,
    Message
} from 'element-ui'


// 创建 axios 实例
const request = axios.create({
    baseURL: '/api',
    timeout: -1,
    withCredentials: true,
  crossDomain: true

})
var quit = true;

// request 拦截器
request.interceptors.request.use(config => {
    //请求拦截
    if (store.state.token) {
        config.headers.common['tokenHead'] = store.state.token
         //console.log("config",config)
    }
    return config
},error =>{
    //出现异常
    return Promise.reject(error);
})
//响应拦截器
request.interceptors.response.use(response =>{
    if (response.data.status == -1) {
        if (quit) {
            //内容
            quit = false;
                    localStorage.removeItem("serviceToken")
                    router.push("/login")
        } else {
            setTimeout(() => {
                quit = true;
            }, 1000);
        }
    }
    return response
},error =>{
    return Promise.reject(error);
})

export default request  //自定义axios对象