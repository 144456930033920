<!-- box1 -->
<template>
  <div id="app">
      <div id="belowStandardAnalyse" style="width: 100%; height: 100%"></div>
  </div>
</template>
<script>
export default {
  name:"app",
  components: {},
  data() {
    //这里存放数据
    return {
       option : {
              // color: ["#e0e5df", "#b5c4b1","96a48b","7b8b6f"],
              tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                      type: 'shadow'
                  }
              },
              grid:{
                left:'11%',
                right:'2%',
                bottom:'12%',
                containLable:true
              },
              legend: {
                  data: ['大一', '大二', '大三','大四']
              },
            
              xAxis: [
                  {
                      type: 'category',
                      axisLabel:{
                        interval: 0
                      },
                      axisTick: {show: false},
                      data: ['机械工程学院', '管理学院', '计算机学院', '经济学院', '电子工程学院']
                  }
              ],
              yAxis: [
                  {
                      // axisLabel:{
                      //     interval: 0
                      // },
                      type: 'value',
                      name:'人数',
                      max:1200,
                      min:0,
                      splitNumber:8
            
                  }
                //     {
                //       type: 'value',
                //       name: '增长率',
                //       min: -100,
                //       max: 200,
                //       splitNunber:7
                     
                // }
                
              ],
              series: [
                  {
                      name: 'first',
                      type: 'bar',
                      barGap: 0,
                      emphasis: {
                          focus: 'series'
                      },
                      data: [800, 800, 800, 800, 800]
                  },
                  {
                      name: '大一',
                      type: 'bar',
                      barGap: 0,
                      // label: labelOption,
                      emphasis: {
                          focus: 'series'
                      },
                       itemStyle:{
                        normal:{
                          color:'#d0c5a7'
                          }
                        },
                      data: [1000, 814, 840, 642, 836]
                  },
                  {
                      name: '大二',
                      type: 'bar',
                      // label: labelOption,
                      emphasis: {
                          focus: 'series'
                      },
                       itemStyle:{
                        normal:{
                          color:'#397290'
                          }
                        },
                      data: [703, 338, 740, 322, 556]
                  },
                  {
                      name: '大三',
                      type: 'bar',
                      // label: labelOption,
                      emphasis: {
                          focus: 'series'
                      },
                     itemStyle:{
                        normal:{
                          color:'#e78046'
                          }
                        },
            
                      data: [384, 210, 427, 147, 340]
                  },
                   {
                      name: '大四',
                      type: 'bar',
                      // label: labelOption,
                      itemStyle:{
                        normal:{
                          color:'#7abbb7'
                          }
                        },
                      emphasis: {
                          focus: 'series'
                      },
                     data: [140, 71, 206, 65, 100]
                  },
                  //  {
                  //   name: '大二',
                  //   type: 'line',
                  //   yAxisIndex: 1,
                  //   // itemStyle:{
                  //   //     normal:{
                  //   //       color:'#4ad2ff'
                  //   //    }
                  //   // },
                  //   data: [50, 20, 3.3, 4.5,90]
                  // },
                  // {
                  //   name: '大一',
                  //   type: 'line',
                  //   yAxisIndex: 1,
                  //   // itemStyle:{
                  //   //     normal:{
                  //   //       color:'#4ad2ff'
                  //   //    }
                  //   // },
                  //   data: [100, 120, -5, 45,9]
                  // },
                  //  {
                  //   name: '大三',
                  //   type: 'line',
                  //   yAxisIndex: 1,
                  //   // itemStyle:{
                  //   //     normal:{
                  //   //       color:'#4ad2ff'
                  //   //    }
                  //   // },
                  //   data: [10, 12, 5, -45,-9]
                  // },
                  //  {
                  //   name: '大四',
                  //   type: 'line',
                  //   yAxisIndex: 1,
                  //   // smooth:false,
                  //   // itemStyle:{
                  //   //     normal:{
                  //   //       color:'#4ad2ff'
                  //   //    }
                  //   // },
                  //   data: [110, 132, 35, -15,89]
                  // }
              ]
            }
          };

      
  },
  props: ["data"],
  computed: {},
  watch: {
    data() {
      this.customized();
    },
  },
  methods: {
    customized() {

      this.option.series[0].data = this.data;
      let myChart = this.$echarts.init(
        document.getElementById("belowStandardAnalyse")
      );
      myChart.setOption(this.option);
      window.onresize = function(){
        myChart.resize();
      }
    },
  },
  mounted() {
    this.customized();
  },
};
</script>
<style lang='less' scoped>
#app {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 10px;
}
.detail {
  width: 120%;
  text-align:center;;
  background-color: #fff;
}
</style>
