<!-- box1 -->
<template>
  <div class="box">
    <div class="detail">
      <div id="belowStandardBarStatic" style="width: 100%; height: 100%"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    //这里存放数据
    return {
     option : { 
        legend: {
          show:false
        },
        grid: {
          top:"1%",
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
           

          data: ['机械', '管理', '计算机', '经济', '电子工程']
        },
        series: [
          {
            // name: 'Direct',
            type: 'bar',
         
            // label: {
            //   show: true
            // },
            // emphasis: {
            //   focus: 'series'
            // },
            // data: [320, 302, 301, 334, 390, 330, 320]
          },
          
          {
            name: 'Affiliate Ad',
            barWidth:25,
            type: 'bar',
            
            label: {
              show: true
            },
          itemStyle: {
                normal: {
                    color: function(params) {
                        // 给出颜色组
                        var colorList = ["#00EEEE","#4EEE94","#6A5ACD","#1E90FF","#00BFFF"];
                        return colorList[params.dataIndex]
                    },
                }
            },
         

            emphasis: {
              focus: 'series'
            },
            data: [2227, 1434,2213, 1176, 1832]
          },
        
        ]
        }
      };
  },
  props: ["data"],
  computed: {},
 watch: {
    data() {
      this.customized();
    },
  },
  methods: {
    customized() {
      this.option.series[0].data = this.data;
      let myChart = this.$echarts.init(
        document.getElementById("belowStandardBarStatic")
      );
      myChart.setOption(this.option);
    },
  },
  mounted() {
     window.onresize = function() {
            _this.myChart.resize()
    }

    this.customized();
  }
  
};
</script>
<style lang='less' scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  // margin-left: 50px;
  margin-top: 5px;
}
.detail {
  width: 100%;
  text-align: left;
  background-color: #fff;
}
</style>