import Vue from 'vue'
import VueRouter from 'vue-router'
import screen from '../views/screen/screen.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'screen',
    component: screen,
    meta: {title:'大屏数据可视化'}
  },
  
  
  
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});


export default router
