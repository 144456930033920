import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import qs from 'qs'
import 'element-ui/lib/theme-chalk/index.css'
import request from './utils/request';
import interfaces from './api/interfaces';
import * as echarts from 'echarts'
Vue.prototype.request = request;
Vue.prototype.interfaces = interfaces;
Vue.prototype.$echarts = echarts
import 'echarts/lib/chart/line' // 折线图
import 'echarts/lib/chart/bar' // 柱形图
import 'echarts/lib/chart/pie' // 饼状图
import store from './store/index'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import elTableInfiniteScroll from 'el-table-infinite-scroll';
Vue.use(elTableInfiniteScroll);
Vue.directive('loadmore', {
  bind(el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', function() {
      let sign = 10
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (scrollDistance <= sign) {
        binding.value()
      }
    })
  }
})

// 按需引入配置功能组件
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markPoint'
import axios from 'axios'
Vue.prototype.$axios = axios



Vue.prototype.$qs = qs
Vue.prototype.getNowFormatDate =  function () {
  var  date =  new  Date();
  var  seperator1 =  "-" ;
  var  year = date.getFullYear();
  var  month = date.getMonth() + 1;
  var  strDate = date.getDate();
  if  (month >= 1 && month <= 9) {
    month =  "0"  + month;
  }
  if  (strDate >= 0 && strDate <= 9) {
    strDate =  "0"  + strDate;
  }
  var  currentdate = year + seperator1 + month + seperator1 + strDate;
  return  currentdate;
};


Vue.config.productionTip = process.env.NODE_ENV === 'production';
console.log(process.env.NODE_ENV)



Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
