<template>
  <div class="app-screen-container">
    <!-- 头部内容 -->
    <div class="app-screen-top">
      <div class="top-title">
         
          
            <h2 style="font-size:20px"> 杭州电子科技大学信息工程学院</h2>
            <div class="top-logo"><img src="../../static/tuanhui.png" width="38px" height="38px"></div>
            <div class="top-logo"><img src="../../static/logo.png" height="35px" weight="35px"></div>

        
      </div>
      <div class="top-title-container">
        <div class="left-title">学生预警模块</div>
        <div class="right-title">活动状态模块</div>
      </div>
    </div>
    <div class="app-screen">
      <!-- 左部内容 -->
      <div class="app-screen-left">
       <left-bar></left-bar>
        
       
       
      </div>
      <!-- 中间部分 -->
      <div class="app-screen-middle">
        <!-- 中间球体 -->
        <div class="earth-container">
          <img src="../../static/middleBall.png" alt="" />
          <!-- 球状字符云 -->
          <svg :width="width" :height="height">
            <a :href="tag.href" v-for="tag in tags">

              <text
                :x="tag.x"
                :y="tag.y"
                :font-size="20 * (600 / (600 - tag.z))"
                :fill-opacity="(400 + tag.z) / 600"
              >
                {{ tag.text }}
              </text>
            </a>
          </svg>
        </div>
        <!-- 中下部分 -->
        <div class="middle-bottom-container">
          <div class="activity-total-number">
            <div style="display: flex; flex-direction: row">
              <h2>学期活动总量:</h2>
              <span>{{ activityTotal }}</span>
            </div>
            <!-- 绿红统计图片 -->
            <div class="activitiy-compare">
              <img src="../../static/compare.png" />
            </div>
            <!-- 校园活动和讲座活动人数 -->
            <div class="marker-container">
              <div class="marker" style="background-color: green"></div>
              <div class="marker-label" style="color: green">校园活动</div>
              <div class="marker-number" style="color: green">
                {{ universityActivity }}
              </div>
              <div
                class="marker"
                style="background-color: brown; margin-left: 160px"
              ></div>
              <div class="marker-label" style="color: brown">讲座活动</div>
              <div class="marker-number" style="color: brown">
                {{ lectureActivity }}
              </div>
            </div>
            <!-- 版权信息 -->
            <div class="author">
              <span>数据来源：第二课堂</span>
              <span>开发：β-house</span>
              
            </div>
          </div>
          <!-- 二维码 -->
          <!-- <div id="qr-code">
            
          </div> -->
        </div>
      </div>
      <!-- 右部内容 -->
      <div class="app-screen-right">
        <!-- 已结束活动内容 -->
        <div class="finished-activity">
          <div class="subtitle">
            <a href="#"><h3>活动章发放情况</h3></a>
          </div>

          <!-- 饼状图 -->
          <div class="finished-activity-pie">
            <finishActivity :data="finishActivityData"></finishActivity>
          </div>
          <!-- 柱状折线图 -->
          <div class="finished-activity-chart">
            <finishActivityChart
              :dataBar="finishActBarData"
              :dataLine="finishActLineData"
              :axisData="finishActAxisData"
            ></finishActivityChart>
          </div>
        </div>
        <!-- 活动列表内容 -->
        <div class="activity-list">
          <div class="subtitle">
            <a href="#"><h3>活动列表</h3></a>
          </div>
          <div class="activity-table">
            <el-table
              ref="table"
              :data="warningTableData"
              v-el-table-infinite-scroll="load"
              style="width: 100%"
              height="100%"
              :row-style="tableRowStyle"
              :header-cell-style="tableHeaderStyle"
            >
              <el-table-column prop="name" label="活动名称" width="200px"> </el-table-column>
              <el-table-column prop="time" label="活动时间" align="center" width="100px">
              </el-table-column>
              <el-table-column prop="number" label="申请章数" align="center">
              </el-table-column>
            
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 未达标数据
import standardRecordChange from "../statistics/standardRecordChange.vue";
// 已结束活动饼图
import finishActivity from "../statistics/finishActivity.vue";
// 未达标人数分析
import belowStandardAnalyse from "../statistics/belowStandardAnalyse.vue";
// 已结束活动柱状折线图
import finishActivityChart from "../statistics/finishActivityChart.vue";
// 各分院未达标人数
// import belowStandardBar from "../statistics/belowStandardBar.vue";
import belowStandardBarStatic from "../statistics/belowStandardBarStatic.vue"
import elTableInfiniteScroll from "el-table-infinite-scroll";


//左侧新样式
import leftBar from "../statistics/leftBar.vue"
export default {
  // 表格自动指令
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  components: {
    standardRecordChange,
    finishActivity,
    belowStandardAnalyse,
    finishActivityChart,
    // belowStandardBar,
    belowStandardBarStatic,
    leftBar
  },
  data() {
    return {
      nomore: false,
      currentPage: 10,
      activityTotal: 370,
      universityActivity: 180,
      lectureActivity: 190,
      width: 600, //svg宽度
      height: 500, //svg高度
      tagsNum: 20, //标签数量
      RADIUS: 230, //球的半径
      speedX: Math.PI / 360, //球一帧绕x轴旋转的角度
      speedY: Math.PI / 360, //球-帧绕y轴旋转的角度
      tags: [],
      count: 0, //起始页数值为0
      loading: false,
      pageObj: {
        nowPage: 0, //当前页数
        pageSize: 4, //每页数量
      },
      disabled: false,
      belowStandardBarData: [1176,1434,1832,2227,2213],
      standardRecordData: [
        {
          value: 582,
          name: "毕业生",
          itemStyle: { color: "#d0c5a7" },
        },
        {
          value: 1508,
          name: "大三",
          itemStyle: { color: "#397290" },
        },
        {
          value: 2660,
          name: "大二",
          itemStyle: { color: "#e78046" },
        },
        {
          value: 4132,
          name: "大一",
          itemStyle: { color: "#7abbb7" },
        },
      ],
      finishActivityData: [
        { value: 30, name: "讲座活动" },
        { value: 50, name: "校园活动" },
      ],
      tableData:
       [
      {
        name: "'一纸灯下'推理活动",
        time: "2021-2022-1",
        number: "50",
        status: "通过",
      },
      {
          name: "心理健康宣传月系列活动",
        time: "2021-2022-1",
        number: "50",
        status: "通过",
      },
        {
          name: "Cypher?night活动",
        time: "2021-2022-1",
        number: "50",
        status: "通过",
      },
        {
          name: "秋季小型招聘会",
        time: "2021-10-20",
        number: "150",
        status: "通过",
      },
        {
          name: "体测志愿者",
        time: "2021-9-28",
        number: "150",
        status: "通过",
      },
       {
          name: "国奖评奖大会",
        time: "2021-7-13",
        number: "150",
        status: "通过",
      },
     

      ],
    warningTableData:
       [
       {
        name: "'一纸灯下'推理活动",
        time: "2022-5-08",
        number: "50",
        status: "通过",
      },
       {
          name: "团学活动讲座",
        time: "2022-5-11",
        number: "50",
        status: "通过",
      },
        {
          name: "志愿活动",
        time: "2022-5-12",
        number: "60",
        status: "通过",
      },
     
        {
          name: "Cypher?night活动",
        time: "2022-5-13",
        number: "50",
        status: "通过",
      },
      
      {
          name: "心理健康宣传月系列活动",
        time: "2022-5-18",
        number: "50",
        status: "通过",
      },
        {
          name: "微型招聘会",
        time: "2022-5-18",
        number: "50",
        status: "通过",
      },
      
      
     

      ],
      
      finishActBarData: [
        50,50,55,58,60,50,55
        // 2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
        // 2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
      ],
      finishActLineData: [
        48,49,54,50,59,49,53
        // 2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
        // 2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
      ],
      finishActAxisData:["推理活动","心理活动","舞社活动","招聘会","志愿活动","团学讲座","教育讲座"],
    };
  },
  computed: {
    CX() {
      //球心x坐标
      return this.width / 2;
    },
    CY() {
      //球心y坐标
      return this.height / 2;
    },
  },
  created() {
    //初始化标签位置
    this.mock();
    var activities=["教育讲座","动员会","宣讲会","招聘会","评选大会","志愿者活动","新生歌会","时间胶囊","青年大学习","马拉松培训",
                    "教育讲座","动员会","宣讲会","招聘会","评选大会","志愿者活动","时间胶囊","新生歌会","青年大学习","马拉松培训"];
    let tags = [];
    for (let i = 0; i < this.tagsNum; i++) {
      let tag = {};
      let k = -1 + (2 * (i + 1) - 1) / this.tagsNum;
      let a = Math.acos(k);
      let b = a * Math.sqrt(this.tagsNum * Math.PI); //计算标签相对于球心的角度
      tag.text = activities[i]
      // tag.text="动员会"
      tag.x = this.CX + this.RADIUS * Math.sin(a) * Math.cos(b); //根据标签角度求出标签的x,y,z坐标
      tag.y = this.CY + this.RADIUS * Math.sin(a) * Math.sin(b);
      tag.z = this.RADIUS * Math.cos(a);
      tag.href = "#"; //给标签添加链接
      tags.push(tag);
    }
    this.tags = tags; //让vue替我们完成视图更新
  },
  mounted() {
    // 调整表格和字符云的自动速度
    const table = this.$refs.table;
    const divData = table.bodyWrapper;
    setInterval(() => {
      divData.scrollTop += 1;
      if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
        divData.scrollTop = 0;
      }
      this.rotateX(this.speedX);
      this.rotateY(this.speedY);
    }, 50);
  },
  methods: {
    // 接口
    mock() {
      // let test = [];
      // for (let i = 0; i < 5; ++i) {
      //   test.push(Math.round(Math.random() * 5));
      // }
      // this.belowStandardBarData = test;
    },
    // 调整表格样式
    tableRowStyle({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        let styleJson = {
          background: "#e9f4ee",
        };
        return styleJson;
      }
      return "";
    },
    tableHeaderStyle({ row, rowIndex }) {
      return "background:#fff;font-weight:bold;color:#2E8B57";
    },
    // 字符云计算位置
    rotateX(angleX) {
      var cos = Math.cos(angleX);
      var sin = Math.sin(angleX);
      for (let tag of this.tags) {
        var y1 = (tag.y - this.CY) * cos - tag.z * sin + this.CY;
        var z1 = tag.z * cos + (tag.y - this.CY) * sin;
        tag.y = y1;
        tag.z = z1;
      }
    },
    rotateY(angleY) {
      var cos = Math.cos(angleY);
      var sin = Math.sin(angleY);
      for (let tag of this.tags) {
        var x1 = (tag.x - this.CX) * cos - tag.z * sin + this.CX;
        var z1 = tag.z * cos + (tag.x - this.CX) * sin;
        tag.x = x1;
        tag.z = z1;
      }
    },
    // 表格无限滚动
    load() {
      
          this.tableData = this.tableData.concat(this.tableData);
      
      
    },
     reset(){
        this.pause()
        setTimeout(() =>{
          this.tableData = []
        },1000)
      },
  },
};
</script>
<style scoped>
/* 头部 */
.app-screen-top {
  height: 12vh;
  background: url("../../static/top.png") no-repeat;
  background-size: cover;
}
/* 头部之下 */
.app-screen-container {
  height: 100vh;
}
/* 头部标题 */
.top-title-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
/* 左右标题样式 */
.left-title {
  color: #346f69;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 16px;
  width: 62%;
}
.right-title {
  color: #346f69;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 16px;
  width: 8%;
}
/* 标题样式 */
.top-title {

  padding-top: 15px;
  text-align: center;
  letter-spacing: 10px;
  font-weight: 550;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.top-logo{
  margin-right: 10px;
}


/* 每个模块小标题样式 */
.subtitle {
  color: white;
  height: 40px;
  background-image: linear-gradient(
    to right,
    rgb(52, 112, 104),
    rgba(255, 255, 255, 0)
  );
}



.subtitle h3 {
  font-weight: 400;
  line-height: 40px;
  padding-left: 25px;
}
/* 定义模块大小 */
.app-screen-left,
.app-screen-right {
  height: auto;
  width: 28%;
}
/* 右部 */
.finished-activity,
.activity-list {
  height: 48%;
  flex-shrink: 1;
}
/* 左部 */
.below-standard-record {
  height: 32%;
  flex-shrink: 1;
}
.below-standard-number {
  height: 29%;
  flex-shrink: 1;
}
.below-standard-analyse {
  justify-content: center;
  height: 35%;
  flex-shrink: 1;
}
/* 中间 */
.app-screen-middle {
  flex-direction: column;
  display: flex;
  width: 44%;
}
/* 标题以下 */
.app-screen {
  display: flex;
  height: 88vh;
  flex-direction: row;
}
.below-standard-pie {
  height: 70%;
}
.below-standard-chart {
  height: 85%;
  width: 90%;
}

/* 各分院未达标人数样式 */
.hexagon {
  height: calc(100% - 45px);
  width: 100%;
  display: flex;
  flex-direction: row;
}
/* 中间部分样式 */
.middle-bottom-container {
  display: flex;
  flex-direction: row;
  height: 30%;
  width: 100%;
}
.earth-container {
  height: 100%;
  width: 100%;
}
/* 活动比较人数和图片 */
.activity-total-number {
  height: 30%;
  width: 95%;
  text-align: center;
}
.activity-total-number h2 {
  justify-content: center;
  margin-left: 220px;
  margin-right: 10px;
}
.activity-total-number span {
  font-weight: bold;
  margin-top: 3px;
  font-size: 20px;
  line-height: 28px;
}
.activitiy-compare {
    width: 100%;
}
.activitiy-compare img {
  margin-top: 10px;
  width: 100%;
}
/* 二维码 */
#qr-code {
  height: 100px;
  width: 100px;

  margin-left: 10px;
}
/* 小圆点 */
.marker {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 10px;
}
.marker-container {
  margin: 8px 10px 0px 40px;
  display: flex;
  flex-direction: row;
}
.marker-label {
  margin-top: -8px;
  font-size: 15px;
  margin-right: 10px;
}
.marker-number {
  margin-top: -8px;
}
/* 版权信息 */

.author span:nth-child(1){
  font-size: 14px;
  margin-right: 130px;
  margin-left: 10px;
}
.author span:nth-child(2){
  font-size: 14px;
  margin-right: 20px;
  margin-left: 0px;
}
.author {
  margin-top: 5px;
  margin-left: 15px;
}
/* 已结束活动 */
.finished-activity-chart {
  height: 50%;
}
.finished-activity-pie {
  height: 32%;
}
/* 地球图片 */
.earth-container img {
  position: absolute;
  width: 520px;
  height: 520px;
  left: 33%;
}
</style>