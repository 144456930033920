<!-- box1 -->
<template>
  <div class="box">
    <div class="detail">
      <div id="finishActivity" style="width: 100%; height: 100%"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      option: {
        color: ["#397290", "#e78046"],
        title: {
          // text: '某站点用户访问来源',
          // subtext: '纯属虚构',
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          icon: "circle",
          // left: "bottom",
          itemWidth: 25, // 设置图例图形的宽
          itemHeight: 15, // 设置图例图形的高
          top: 20,
          // x 设置水平安放位置，默认全图居中，可选值：'center' ¦ 'left' ¦ 'right' ¦ {number}（x坐标，单位px）
          x: "220px",
          formatter: (name) => {
            let data = this.data;
            let total = 0;
            let target;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            let arr = [name + "  " + (target / total) * 100];
            return arr.join("\n");
          },
          //   // y 设置垂直安放位置，默认全图顶端，可选值：'top' ¦ 'bottom' ¦ 'center' ¦ {number}（y坐标，单位px）
          //   y: "top",
        },
        series: [
          {
            name: "已结束活动",
            type: "pie",
            startAngle: 50,
            radius: [10, 50],
            width: 200,
            itemStyle: {
              normal: {
                label: {
                  show: false, //隐藏标示文字
                },
                labelLine: {
                  show: false, //隐藏标示线
                },
                borderColor: "#fff",
                borderWidth: 5,
              },
            },
            avoidLabelOverlap: true, 
            hoverOffset: 15, //设置选中高出的部分 数值越大越高
            labelLine: {
              normal: {
                show: false,
                position: "inner",
              },
            },
            data: [],
            emphasis: {
              label: {
                show: false,
              },
              labelLine: {
                show: true,
              },
            },
          },
        ],
      },
    };
  },
  props: ["data"],
  computed: {},
  watch: {
    data() {
      this.customized();
    },
  },
  methods: {
    customized() {
      this.option.series[0].data = this.data;
      let myChart = this.$echarts.init(
        document.getElementById("finishActivity")
      );
      myChart.setOption(this.option);
    },
  },
  mounted() {
    this.customized();
  },
};
</script>
<style lang='less' scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 5px;
}
.detail {
  width: 100%;
  text-align: left;
  background-color: #fff;
}
</style>