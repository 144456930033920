<!-- box1 -->
<template>
  <div class="box">
    <div class="detail">
      <div id="finishActivityChart" style="width: 100%; height: 100%"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    //这里存放数据
    return {
      num: 5,
      firstEnter: true,
      option: {
        title: {
          // text: '动态数据',
          // subtext: '纯属虚构'
        },
        legend: {
          data: ["报名人数", "参与人数"],
          show: true,
        },
        grid: {
          x: "10%",
          top: "24%",
          bottom: "25px",
          right: "2%",
          width: "82%",
        },
        // dataZoom: {
        //     show: false,
        //     start: 0,
        //     end: 100
        // },
        xAxis: [
          {
            type: "category",
            show: true,
            boundaryGap: true,
            data: (function () {
              var now = new Date();
              var res = [];
              var len = 5;
              while (len--) {
                res.unshift();
                now = new Date(now - 2000);
              }
              return res;
            })(),
          },
          {
            type: "category",
            show: true,
            boundaryGap: true,
            // data: (function () {
            //   var res = [];
            //   var len = 5;
            //   while (len--) {
            //     res.push(10 - len);
            //   }
            //   return res;
            // })(),
          },
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
            name: "报名人数",
            max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            show: true,
          },
          {
            type: "value",
            scale: true,
            name: "参与人数",
            max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            show: true,
          },
        ],
        series: [
          {
            name: "报名人数",
            type: "bar",
            barWidth: 30, //柱图宽度
            xAxisIndex: 1,
            yAxisIndex: 1,
            color: ["#EEEED1"],
            label: {
              normal: {
                show: true,
                // position: ''
              },
            },
            data: [],
          },
          {
            name: "参与人数",
            type: "line",
            color: ["#008B00"],
            showAllSymbol: true,
            data: [],
          },
        ],
      },
    };
  },
  props: ["dataBar", "dataLine", "axisData"],
  computed: {},
  watch: {
    data() {
      this.customized();
    },
  },
  methods: {
    getZoomData(myChart) {
      if (!this.firstEnter) {
        this.axisData.push(this.axisData.shift());
        this.dataBar.push(this.dataBar.shift());
        this.dataLine.push(this.dataLine.shift());
      }

      let axisData = this.axisData.slice(0, 5);
      let dataBar = this.dataBar.slice(0, 5);
      let dataLine = this.dataLine.slice(0, 5);

      this.option.xAxis[0].data = axisData;
      this.option.series[0].data = dataBar;
      this.option.series[1].data = dataLine;

      myChart.setOption(this.option);
    },
    customized() {
      let myChart = this.$echarts.init(
        document.getElementById("finishActivityChart")
      );

      setInterval(() => {
        this.getZoomData(myChart);
        this.firstEnter = false;
      }, 5500);
    },
  },
  mounted() {
    this.customized();
  },
};
</script>
<style lang='less' scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 10px;
}
.detail {
  width: 100%;
  text-align: center;
  background-color: #fff;
}
</style>